@import '../styles/common/media-queries.scss';
@import '../styles/common/rtl.scss';
.close_btn_wr{
  z-index: 9;
}
.close_btn{
  top:10px;
  @include rtl(right,-10px ,unset);
  @include rtl(left,unset ,-10px);
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  // position: fixed;
  width: 100vw;
  background-color: #fff;
  top: 0;
  z-index: 9;
  .contents_menu_back{
    .contents_menu_back_btn{
      @include rtl(transform,scaleX(1),scaleX(-1));
    }
    
  }
  .contents_logo{
    display: flex;
    justify-content: center;
    .logo_wr{
      width: 119px;
      display: flex;
      cursor: pointer;
      @include res-down-high(sm){
        width: 119px;
        height: 47px;
      }
      .logo{
        width: 119px;
        height: auto;
        display: block;
        @include res-down-high(sm){
          width: 170px;
          height: 50px;
        }
      }
    }
  }
  .contents_menu_open{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .menu_open{
      margin-left: auto;
      .svg_wr{
        width: 55px;
        display: flex;
        cursor: pointer;
      }
    }
    button{
      @include rtl(font-family,NotoSans ,GE_SS_Two);
     
    }
    .text{
      font-size: 14px;
      font-weight: 700;
      padding: 0 5px;
    }
  }
  .contents_lang{
    display: flex;
    justify-content: flex-end;
  }
}

.drawer{
  .listhead{
    display: flex;
    font-size: 18px;
    color: #8c8c8c;
    margin-bottom: 10px;
    padding:0 2.5rem;
    align-items: center;
    .MuiSvgIcon-root,svg{
      @include rtl(margin,0 10px 0 0 ,0 0 0 10px);
    }
    @media (max-height: 560px) and (orientation: portrait) {
      margin-bottom: 0;
    }
  }
}

.menu{
  display: flex;
  flex-direction: column;
  @media (min-height: 580px) and (orientation: portrait) {
    min-height: calc(100vh - 56px);
    justify-content: center;
    top: -23px;
  }
  .link{
    padding:1.25rem 2.5rem;
    color: #707070;
    @media (max-height: 580px) and (orientation: portrait) {
      padding:2.4vh 2.5rem;
    }
    &.active{
      color:#000;
    }
    .text{
      @include rtl(font-family,NotoSans ,GE_SS_Two);
      font-size: 21px;
      margin: 0;
      @include rtl(text-align,left ,right);
      >span{
        font-size: 24px;
        color: #8c8c8c;
        display: flex;
        flex-direction: column;
        line-height: 1;
        .text_primary{
          font-weight: 300;
          display: flex;
          align-items: center;
         .MuiSvgIcon-root,svg{
            @include rtl(margin,0 10px 0 0 ,0 0 0 10px);
          }
        }
        .text_primary_lang{
          font-weight: 300;
          display: flex;
          align-items: center;
          @include rtl(font-family,GE_SS_Two,NotoSans);
          span{
            margin:0 5px;
          }
        }
        .text_secondary{
          font-weight: 700;
        }
      }
      
    }
  }
}
.active{
  color:#000;
}